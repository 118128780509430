<template>
    <v-container cols="12" md="12" class="pa-md-3 pa-0 pb-0 pl-md-0 pt-0">
        <v-row justify="center">  
            <v-col md="9" cols="10" class="pa-md-0">
                <v-col cols="3" class="pl-0 pb-0" v-if="$vuetify.breakpoint.mdAndUp" >
                    <v-row>
                        <v-col md="2">
                            <img src="@/assets/icone_cobrar2.png">
                        </v-col>
                        <v-col md="6" align="center" class="pt-md-4 title">
                            Cobrar
                        </v-col>
                    </v-row>
                </v-col>
                <v-col md="4" class="pa-0" v-if="receivingValue && $vuetify.breakpoint.smAndDown">
                    <h5 class="label-input-value">Recebendo</h5>
                </v-col>
                <v-col md="4" class="pa-0" v-if="changeAlias && $vuetify.breakpoint.smAndDown">
                    <h5 class="label-input-value">Alterar chave pix</h5>
                </v-col>  
                <v-col md="6" class="pl-0 pa-md-0 pb-md-8" v-if="$vuetify.breakpoint.mdAndUp || receivingValue && $vuetify.breakpoint.smAndDown">
                    Quem for te pagar vai ver essas informações quando escanear o QR Code:
                </v-col>
                <v-col md="4" class="pl-0 pa-md-0 pb-8" v-if="changeAlias && $vuetify.breakpoint.smAndDown">
                    Selecione a Chave Pix que prefere utilizar para concluir a operação:
                </v-col>
                <v-form class="pl-0 pr-0 pb-0" cols="12" ref="form" lazy-validation v-if="generateData">
                    <v-col md="4" class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
                        <h5 class="label-input-value">Qual valor você deseja receber?</h5>
                    </v-col>
                    <v-col md="4" cols="8" class="pa-0 pb-12 pt-3" v-if="defineValue && $vuetify.breakpoint.smAndDown">
                        <h5 class="label-input-value">Qual valor você quer receber?</h5>
                    </v-col>
                    <v-col cols="12" md="4" v-if="!changeAlias && $vuetify.breakpoint.smAndDown || $vuetify.breakpoint.mdAndUp"  class="pl-0 pr-0 pb-md-2 pb-0">
                        <v-text-field ref="value" class="input-collect mt-0 pt-0"
                            required
                            maxlength="20"
                            v-model.lazy="value"
                            v-money="money"
                            validate-on-blur
                            :append-icon="$vuetify.breakpoint.mdAndUp || $vuetify.breakpoint.smAndDown && receivingValue ? 'mdi-pencil' : ''"
                            :rules= "validateValue"
                            :hint="$vuetify.breakpoint.mdAndUp ? 'Caso não desejar informar um valor, manter o campo em branco' : ''" 
                            persistent-hint                
                        >{{value}}</v-text-field>
                    </v-col>
                    <v-col cols="12" @click="pushToReceivingValue" class="value-link pa-0 pb-12" v-if="$vuetify.breakpoint.smAndDown && defineValue">
                        Não especificar um valor >
                    </v-col>
                    <v-col cols="12" md="6" class="input-msg pl-0 pt-md-6 pb-md-0 pb-10 pr-0" v-if="generateData && $vuetify.breakpoint.mdAndUp || receivingValue  && $vuetify.breakpoint.smAndDown"> 
                        <v-textarea
                        class="box-msg pb-0 pt-0"
                        background-color= "#F2F2F2"
                        placeholder="Escreva uma mensagem..."
                        v-model="msg"
                        maxlength="35"
                        rows="2"
                        counter="35"
                        color="#D70472"
                        ></v-textarea>    
                    </v-col>
                    <v-col v-if="generateData && $vuetify.breakpoint.mdAndUp || receivingValue  && $vuetify.breakpoint.smAndDown" class="pa-0">
                        <v-col md="6" class="pa-0 pt-4">
                            <h5>Chave Pix</h5>
                        </v-col>
                        <v-col @click="changeKey" cols="12" class="pa-0" v-if="receivingValue  && $vuetify.breakpoint.smAndDown">
                            <v-text-field
                            class="mobile-labelkey"
                            :label="labelKey"
                            v-model="selectKey"
                            suffix="Alterar >"
                            readonly
                            color="#D70472" 
                            ></v-text-field>
                        </v-col>
                        <v-col md="6" class="pl-0 pr-0 pb-0 pt-10 options-keys" v-if="generateData && $vuetify.breakpoint.mdAndUp">
                            <v-select
                            :items="optionsKeys"
                            :label="labelKey"
                            suffix="Alterar"
                            color="#D70472"
                            dense
                            v-model="selectKey"
                            :rules = "keyRules"
                            item-text="key"
                            >
                                <template slot="item" slot-scope="data">
                                    <v-row  class="drop-list-select-keys">
                                        <v-col cols="12" class="pb-0"> <strong>{{ data.item.description }} </strong></v-col>
                                        <v-col cols="12" class="pt-0" v-if="data.item.description === 'CNPJ'"> {{ service.documentFormat(data.item.key) }} </v-col>
                                        <v-col cols="12" class="pt-0" v-if="data.item.description === 'Celular'"> {{ service.mobileFormat(data.item.key) }} </v-col>
                                        <v-col cols="12" class="pt-0" v-if="data.item.description === 'E-mail' || data.item.description === 'Aleatória'"> {{ data.item.key }} </v-col>
                                    </v-row>
                                </template>
                            </v-select>
                        </v-col>
                        <v-row v-if="$vuetify.breakpoint.smAndDown" class="pt-0">
                            <v-col cols="6" class="pa-0 pt-10" >
                                <v-col cols="6" class="col-data-key pt-0 pb-0 data-keys">
                                    <h5>Nome</h5>
                                    <p>{{registration.name}}</p>
                                </v-col>
                                <v-col cols="6" class="col-data-key pt-0 data-keys">
                                    <h5>CNPJ</h5>
                                    <p class="data-key-document">{{service.documentFormat(registration.document)}}</p>
                                </v-col>
                            </v-col>
                            <v-col cols="6" class="pa-0 pt-10">
                                <v-col cols="6" class="col-data-key pt-0 pb-0 data-keys">
                                    <h5>Instituição</h5>
                                    <p>Finago</p>
                                </v-col>
                                <v-col cols="6" class="col-data-key pt-0 data-keys">
                                    <h5>Identificador</h5>
                                    <p>Gerado automaticamente</p>
                                </v-col>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-form>

                <v-row v-if="showData" class="data-qr pl-md-3 pt-md-5 pb-md-6">
                    <v-col md="3" cols="12" class="pl-0 img-qr">
                        <img class="size-qr" :src="`data:image/png;base64,${getQrCodeData.qrCodeBase64Image}`">
                    </v-col>
                    <v-col md="4" cols="12" class="text-qr pl-md-10">
                        <h3 class="title title-qr-share mb-3">Mostre o QR code ou compartilhe o link</h3>
                        Quem for te pagar precisa abrir o app em que vai fazer a tranferência e escanear o QR code
                    </v-col>
                </v-row>
                <v-col md="7" cols="12" v-if="showData" class="data-payment">
                    <v-row>    
                        <v-col cols="12" md="6" class="title value-qr pl-md-5">
                            Valor a ser pago: 
                            <v-text-field 
                                class="text-field-value" 
                                readonly
                                v-money="money"
                                maxlength="20"
                                v-model.lazy="value"
                                color="#D70472"                
                            >{{value}}</v-text-field>
                        </v-col>   
                        <v-col cols="12" md="6" class="msg-qr pl-2 pb-10 pb-md-3 pr-md-5" align-self="end">
                            <v-textarea
                            v-if="getQrCodeData.description"
                            class="msg-data"
                            readonly
                            background-color= "#F2F2F2"
                            v-model="getQrCodeData.description"
                            maxlength="35"
                            rows="2"  
                            color="#D70472"
                            ></v-textarea>    
                        </v-col>   
                    </v-row>
                        <v-col cols="12" class="pa-0 pt-0 pb-4">
                            <h3 class="label-key">Chave Pix</h3>
                        </v-col>
                        <v-col cols="12" class="data-keys pa-0">
                            <h5 >{{labelKey}}</h5>
                            <p>{{getFormatedAlias}}</p>
                        </v-col>
                        <v-row>
                            <v-col cols="6" class="col-data-key pt-0 data-keys">
                                <h5>Nome</h5>
                                <p>{{getQrCodeData.name}}</p>
                            </v-col>
                            <v-col cols="6" class="col-data-key pt-0 data-keys">
                                <h5>Instituição</h5>
                                <p>{{getQrCodeData.bankName}}</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="col-data-key pt-0 pr-0 data-keys">
                                <h5>CNPJ</h5>
                                <p>{{service.documentFormatHidden(getQrCodeData.document)}}</p>
                            </v-col>
                            <v-col cols="6" class="col-data-key pt-0 data-keys">
                                <h5>Identificador</h5>
                                <p class="qr-identifier">{{getQrCodeData.identifier}}</p>
                            </v-col>
                        </v-row>
                </v-col>
                <v-col md="10" cols="12" class="select-cards-hide aleatory mr-10 ml-0 mb-2"
                v-for="(item, i) in getDataKeys.items[0].alias" :key="i" v-if="item.status === true && changeAlias && $vuetify.breakpoint.smAndDown">
                    <v-row justify="space-between">
                        <v-col cols="7" class="pt-0 pb-0">
                        <v-row>
                            <v-col md="4" cols="12" class="select-titles pb-0 pl-md-8 pl-6 pr-0 pr-md-2 pt-md-3 pt-1">{{item.type === 'cnpj' ? 'CNPJ' : item.type === 'random' ? 'Aleatória' : item.type === 'mobile_phone' ? 'Celular' : item.type === 'email' ? 'E-mail' : "" }}</v-col>
                            <v-col md="7" cols="5" v-if="item.type === 'cnpj'" class="data-key mt-1 pl-md-0 pl-0 pt-md-3 pt-0 ml-md-2 ml-6">{{service.documentFormat(item.name)}}</v-col>
                            <v-col md="7" cols="5" v-if="item.type === 'mobile_phone'" class="data-key mt-1 pl-md-0 pl-0 pt-md-3 pt-0 ml-md-2 ml-6">{{service.mobileFormat(item.name)}}</v-col>
                            <v-col md="7" cols="5" v-if="item.type === 'random' || item.type === 'email' " class="data-key mt-1 pl-md-0 pl-0 pt-md-3 pt-0 ml-md-2 ml-6">{{item.name}}</v-col>
                        </v-row>
                        </v-col>
                        <v-col cols="5" align="right" class="pr-6 pt-2">
                        <v-btn
                            rounded
                            color="#D70472"
                            outlined
                            dark
                            x-small
                            class="button"
                            @click="requestSelect(item.name, item.type)"
                        >Selecione</v-btn>
                        </v-col>
                    </v-row>
                </v-col>  
                <v-col md="6" class="pa-0">
                    <v-row class="row-buttons-create">
                        <v-col class="pt-0 pa-md-3 pa-0" md="6" cols="12" >
                            <v-btn v-if="generateData && $vuetify.breakpoint.mdAndUp || defineValue && $vuetify.breakpoint.smAndDown || receivingValue && $vuetify.breakpoint.smAndDown" @click="$router.push(`/customer/digitalAccount/pix`)" class="button" :color="$vuetify.breakpoint.mdAndUp ? '#C4C4C4' : '#8C8C8C'" block rounded :dark="$vuetify.breakpoint.mdAndUp" :outlined="$vuetify.breakpoint.smAndDown" :x-large="$vuetify.breakpoint.smAndDown">
                                Cancelar
                            </v-btn>
                            <v-btn v-if="showData && $vuetify.breakpoint.mdAndUp"  @click="redirectToCollect" class="button" color="#D70472" block rounded outlined>
                                Nova Cobrança
                            </v-btn>
                            <v-btn v-if="changeAlias && $vuetify.breakpoint.smAndDown" @click="pushToReceivingValue" class="button" color="#8C8C8C" x-large block rounded outlined>
                                Cancelar
                            </v-btn>
                            <v-btn v-if="showData && $vuetify.breakpoint.smAndDown" @click="$router.push(`/customer/digitalAccount/pix`)" class="button" color="#8C8C8C" x-large block rounded outlined>
                                Fechar
                            </v-btn>
                        </v-col>
                        <v-col class="pa-md-3 pa-0 pb-5" md="6" cols="12"> 
                            <v-btn v-if="generateData && $vuetify.breakpoint.mdAndUp || receivingValue && $vuetify.breakpoint.smAndDown" @click="generatePayment" :disabled="disabledGenerate" :light="disabledGenerate" class="button" color="#D70472" :x-large = "$vuetify.breakpoint.smAndDown" block rounded :dark="!disabledGenerate" >
                                    {{$vuetify.breakpoint.mdAndUp ? 'Gerar QR Code' : 'Criar QR Code' }}
                            </v-btn>
                            <v-btn v-if="showData" class="button" @click="copyUrl" color="#D70472" :x-large = "$vuetify.breakpoint.smAndDown" block rounded dark >
                                    Copiar link
                            </v-btn>
                            <v-btn @click="pushToReceivingValue" v-if="defineValue && $vuetify.breakpoint.smAndDown " class="button" color="#D70472" :x-large = "$vuetify.breakpoint.smAndDown" block rounded dark >
                                    Continuar
                            </v-btn>
                            <v-btn @click="pushToReceivingValue" v-if="changeAlias && $vuetify.breakpoint.smAndDown " class="button" color="#D70472" :x-large = "$vuetify.breakpoint.smAndDown" block rounded dark >
                                    Confirmar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { VMoney } from 'v-money';
import service from '../../../../service/index';

export default {
  directives: {
    money: VMoney,
  },
  data() {
    return {
        service,
        defineValue: true,
        receivingValue: false,
        changeAlias: false,
        disabledGenerate: false,
        generateData: true,
        showData: false,
        value: 0.00,
        email: "nome.sobrenome@emailempresa.com.br",
        name: "Maria Zilda",
        cnpj: "00.000.000/0000-00",
        institution: "Finago",
        identifier: "Gerado automaticamente",
        cell: "(11) 0 0000-0000",
        msg: "",
        optionsKeys: [],
        labelKey: "",
        selectKey: {},
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: true 
        },
        keyRules: [
         valueInput => !!valueInput.length || 'Seleção de chave obrigatória',],
        validateValue: [
            valueInput => (!!valueInput && this.service.cleanCurrencyAll(valueInput) >= 0) || 'Digite um valor entre R$0,01 e R$99.999.999,99',
            valueInput => (!!valueInput && this.service.cleanCurrencyAll(valueInput) < 1000000) || 'Digite um valor entre R$0,01 e R$999.999,99',
        ],
    }
  },
  watch: {
    selectKey(value){
        const result = this.optionsKeys.find( el => el.key === value );
        this.labelKey = result.description
    }
  },
  computed: {
      ...mapGetters({
            role: 'Auth/getRole',
            getDataKeys:'Pix/getAllDataKeys',
            getQrCodeData: 'Pix/getQrCodeData',
            registration: 'Registration/getConsultRegistration',
        }),
        getFormatedAlias(){
          return this.labelKey === "CNPJ" ? this.service.documentFormat(this.getQrCodeData.alias) : this.labelKey === "Celular" ? this.service.mobileFormat(this.getQrCodeData.alias) : this.getQrCodeData.alias
        }
  }, 
  methods: {
    ...mapActions({
      getAllDataKeys: 'Pix/getAllPixKeys',
      generateQRcode: 'Pix/generateQRcode',
      setConsultRegistration: 'Registration/setConsultRegistration',
    }),
    async requestSelect(name, type) {
        if(type === "cnpj"){
            this.selectKey = this.service.documentFormat(name)
        }else if(type === "mobile_phone"){
            this.selectKey = this.service.mobileFormat(name)
        }else if(type === "email" || type === "random"){
            this.selectKey = name
        }
    },
    async generatePayment(){
        if(this.$refs.form.validate()){
            this.disabledGenerate = true
            let type = ''
            await  this.labelKey === "CNPJ" ? type = 'cnpj' : this.labelKey === 'Aleatória' ? type = 'random' : this.labelKey === 'Celular' ? type = 'mobile_phone' : this.labelKey === 'E-mail' ? type = 'email' : ""
            if(this.labelKey === "CNPJ") {
                await this.generateQRcode({
                    totalAmount: parseFloat(this.service.cleanCurrency(this.value)),
                    description: this.msg,
                    alias: this.service.cleanDocument(this.selectKey),
                    aliasType: type,
                })
            } else if (this.labelKey === 'Celular'){
                await this.generateQRcode({
                totalAmount: parseFloat(this.service.cleanCurrency(this.value)),
                description: this.msg,
                alias: this.service.cleanMobilePhone(this.selectKey),
                aliasType: type,
                })
            } else if ( this.labelKey === 'Aleatória' || this.labelKey === 'E-mail' ) {
                await this.generateQRcode({
                    totalAmount: parseFloat(this.service.cleanCurrency(this.value)),
                    description: this.msg,
                    alias: this.selectKey,
                    aliasType: type,
                })
            }
            this.generateData = !this.generateData
            this.showData = !this.showData
            this.receivingValue = false
        }
    },
    copyUrl() {
        let url = this.getQrCodeData.code
        const copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        }
        copyToClipboard(url)
    },
    async initializeSelect(){
        this.optionsKeys = []
        for(let i = 0; i <= this.getDataKeys.items[0].alias.length; i++) {
            if(this.getDataKeys.items[0].alias[i]) {
                if(this.getDataKeys.items[0].alias[i].status === true) {
                    let item = this.getDataKeys.items[0].alias[i].type 
                    let type = ''
                    await  item === 'cnpj' ? type = "CNPJ" : item === 'random' ? type = 'Aleatória' : item === 'mobile_phone' ? type = 'Celular' : item === 'email' ? type = 'E-mail' : ""
                    if(item === 'cnpj'){
                        this.optionsKeys.push({
                            key: this.service.documentFormat(this.getDataKeys.items[0].alias[i].name),
                            description: type  
                        })   
                    } if(item === 'mobile_phone') {
                        this.optionsKeys.push({
                            key: this.service.mobileFormat(this.getDataKeys.items[0].alias[i].name),
                            description: type  
                        })   
                    } if(item === 'random' || item === 'email') {
                        this.optionsKeys.push({
                            key: this.getDataKeys.items[0].alias[i].name,
                            description: type  
                        }) 
                    }
                }
            }
        }
        this.selectKey = this.optionsKeys[0].key
        this.labelKey = this.optionsKeys[0].description 
    },
    changeKey(){
        this.changeAlias = true
        this.receivingValue = false
        this.defineValue = false
    },
    pushToReceivingValue(){
        if(this.$refs.form.validate()){
            this.receivingValue = true
            this.defineValue = false
            this.changeAlias = false
        }
    },
    async redirectToCollect(){
        await this.initializeSelect()
        this.showData = false
        this.generateData = true
        this.value = 0.00
        this.msg = ''
        this.disabledGenerate = false
    }
  }, 
  async created() {
    await this.setConsultRegistration();
    await this.getAllDataKeys({
        "page":1,
        "perPage":20
    })
    await this.initializeSelect()
  } 
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/digitalAccount/pix.scss';

</style>
